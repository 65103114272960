<template>
  <div class="px-4 py-2 robot">
    <!-- <div class="py-4 font-bold text-black border-b border-gray-300 title">
      机器人管理
    </div> -->
    <div class=" content_item text-a-right">
      <div class='tanchuangc' v-if="tanchuang">
        <div class="add_bot">添加机器人</div>
        <div @click="close" class="icons-list"><a-icon type="close" /></div>
        <div class="bot_base">基本信息</div>
        <div class=" mt-4 mb-4 flex">
          <div class="flex ppp111">
             <div class="flex text-a-right ppp111str">采样类型：</div>
             <div class="flex ppp111">               <a-select
                  default-value="男上模"
                  style="width: 100%"
                  size="small"
                  @change="handleChange"
                >
                  <a-select-option v-for="(item,index) in this.threerobot" :value=item.id> {{item.name}} </a-select-option>

                </a-select></div>
          </div>
          <div class="flex ppp111">             
             <div class="flex text-a-right ppp111str">机器人名称：</div>
             <div class="flex ppp111"><a-input size="small" placeholder="请输入" v-model="name" /></div></div>
        </div>
        <div class="mt-4 mb-4 flex">
          <div class="flex ppp111">
             <div class="flex text-a-right ppp111str">机器人编码：</div>
             <div class="flex ppp111"><a-input class="" size="small" placeholder="请输入" v-model="robotCode" /></div>
          </div>
          <div class="flex ppp111">             
             <div class="flex text-a-right ppp111str">价格：</div>
             <div class="flex ppp111"><a-input
                size="small"
                placeholder="请输入"
                v-model="money"
                type="number"
              /></div></div>
        </div>
        <div class="mt-4 mb-4 flex">
          <div class="flex ppp111">
             <div class="flex text-a-right ppp111str">工作日期：</div>
             <div class="flex ppp111">           <a-range-picker
                size="small" :disabled-date="disabledDate"
                style="width: 100%"
                :placeholder="['开始日期', '结束日期']"
                @change="onRangeChange"
              >
                <template slot="dateRender" slot-scope="current">
                  <div
                    class="ant-calendar-date"
                    :style="getCurrentStyle(current)"
                  >
                    {{ current.date() }}
                  </div>
                </template>
              </a-range-picker></div>
          </div>
          <div class="flex ppp111">             
             <div class="flex text-a-right ppp111str">工作时间：</div>
             <div class="flex ppp111">              <a-time-picker
                :value="value"
                placeholder="开始时间" 
                format="HH:mm"
                size="small"
                @change="onChange"
              />
              <div class="px-2"></div>
              <a-time-picker
                :value="value2"
                placeholder="结束时间"
                format="HH:mm"
                size="small"
                @change="onChange2"
              /></div></div>
        </div>
        
        <div class="bot_base">体型范围</div>

        <div class="mt-4 mb-4 liebiao">

                
              <div   v-for="(val,indexa) in this.threerobot[this.index].attr" :key = 'indexa' class="mt-2 mb-2 liebiao1">
                
                
                <div class="flex text-a-right ppp111str">{{val.name}}：</div>
                <div class="flex ppp111">
                  <a-input class=""
                    size="small"
                    placeholder= ""
                    v-model="threerobot[index].attr[indexa].min"
                    type="number"
                  />~<a-input class=""
                    size="small"
                    placeholder="请输入"
                    v-model="threerobot[index].attr[indexa].max"
                    type="number"
                  />  <span  class="span_b2">&nbsp;CM</span>
                </div>

              </div>
            

            <!-- <div v-for="(value,index) in nanmoshang">
              
              <div class="mt-2 mb-2 liebiao1">
                <div class="flex text-a-right ppp111str">{{ value.name }}：</div>
                <div class="flex ppp111">
                  <a-input class="sg_input"
                    size="small"
                    placeholder="请输入"
                    v-model="nanmoshang[index]['value'][0]"
                    type="number"
                  />~<a-input class="sg_input"
                    size="small"
                    placeholder="请输入"
                    v-model="money"
                    type="number"
                  />  <span  class="span_b2">&nbsp;CM</span>
                </div>
              </div>
            </div> -->
        </div>

          
          <div  class="rob_button py-2">
          <a-button size="small" @click="reset">重置</a-button>
          <span class="px-2"></span>
          <a-button size="small" type="primary" @click="addRobotEvent">
            确定
          </a-button>
        </div>
      </div>
      <div class='tanchuangc' v-if="xiugai">
        <div class="add_bot">修改机器人</div>
        <div @click="close" class="icons-list"><a-icon type="close" /></div>
        <div class="bot_base">基本信息</div>
        <div class=" mt-4 mb-4 flex">
          <!-- <div class="flex ppp111">
           <div class="flex text-a-right ppp111str">采样类型：</div>
             <div class="flex ppp111">               <a-select
                  :default-value=this.duiyingguanxi[this.xiugaiDefault]
                  style="width: 100%"
                  size="small"
                  @change="handleChangex"
                >
                <a-select-option v-for="(item,index) in this.threerobot"  :value=item.id> {{item.name}} </a-select-option>
                </a-select></div> 
          </div> -->
          <div class="flex ppp111">             
             <div class="flex text-a-right ppp111str">机器人名称：</div>
             <div class="flex ppp111"><a-input size="small" placeholder="请输入" v-model="xiugailaiyuanObj.name" /></div></div>
             <div class="flex ppp111">             
             <div class="flex text-a-right ppp111str">价格：</div>
             <div class="flex ppp111"><a-input
                size="small"
                placeholder="请输入"
                v-model="xiugailaiyuanObj.money"
                type="number"
              /></div></div>
        </div>
        <div class="mt-4 mb-4 flex">
          <!-- <div class="flex ppp111">
             <div class="flex text-a-right ppp111str">机器人编码：</div>
             <div class="flex ppp111"><a-input class="" size="small" placeholder="请输入" v-model="robotCode" /></div>
          </div> -->
          <!-- <div class="flex ppp111">             
             <div class="flex text-a-right ppp111str">价格：</div>
             <div class="flex ppp111"><a-input
                size="small"
                placeholder="请输入"
                v-model="xiugailaiyuanObj.money"
                type="number"
              /></div></div> -->
        </div>
        <!-- <div class="mt-4 mb-4 flex">
          <div class="flex ppp111">
             <div class="flex text-a-right ppp111str">工作日期：</div>
             <div class="flex ppp111">           <a-range-picker
                size="small" :disabled-date="disabledDate"
                style="width: 100%"
                :placeholder="['开始日期', '结束日期']"
                @change="onRangeChange"
              >
                <template slot="dateRender" slot-scope="current">
                  <div
                    class="ant-calendar-date"
                    :style="getCurrentStyle(current)"
                  >
                    {{ current.date() }}
                  </div>
                </template>
              </a-range-picker></div>
          </div>
          <div class="flex ppp111">             
             <div class="flex text-a-right ppp111str">工作时间：</div>
             <div class="flex ppp111">              <a-time-picker
                :value="value"
                placeholder="开始时间" 
                format="HH:mm"
                size="small"
                @change="onChange"
              />
              <div class="px-2"></div>
              <a-time-picker
                :value="value2"
                placeholder="结束时间"
                format="HH:mm"
                size="small"
                @change="onChange2"
              /></div></div>
        </div> -->
        
        <div class="bot_base">体型范围</div>

        <div class="mt-4 mb-4 liebiao">

                
              <div   v-for="(val,indexa) in this.xiugailaiyuanCfg" :key = 'indexa' class="mt-2 mb-2 liebiao1">
                
                
                <div class="flex text-a-right ppp111str">{{val.name}}：</div>
                <div class="flex ppp111">
                  <a-input class=""
                    size="small"
                    placeholder= ""
                    v-model="val.min"
                    type="number"
                  />~<a-input class=""
                    size="small"
                    placeholder="请输入"
                    v-model="val.max"
                    type="number"
                  />  <span  class="span_b2">&nbsp;CM</span>
                </div>

              </div>
            

            <!-- <div v-for="(value,index) in nanmoshang">
              
              <div class="mt-2 mb-2 liebiao1">
                <div class="flex text-a-right ppp111str">{{ value.name }}：</div>
                <div class="flex ppp111">
                  <a-input class="sg_input"
                    size="small"
                    placeholder="请输入"
                    v-model="nanmoshang[index]['value'][0]"
                    type="number"
                  />~<a-input class="sg_input"
                    size="small"
                    placeholder="请输入"
                    v-model="money"
                    type="number"
                  />  <span  class="span_b2">&nbsp;CM</span>
                </div>
              </div>
            </div> -->
        </div>

          
          <div  class="rob_button py-2">
          <!-- <a-button size="small" @click="reset">重置</a-button> -->
          <span class="px-2"></span>
          <a-button size="small" type="primary" @click="xiugaiRobotEvent">
            修改
          </a-button>
        </div>
      </div>
      <a-button size="small" type="primary" @click="addDiv">
        添加机器人 
      </a-button>

      <div class="pt-4">
        <a-table
          :data-source="dataSource"
          :columns="columns"
          :pagination="pagination"
        >
        

          <template slot="operation" slot-scope="text, record">
            <div
            style="float:left"
              @click="() => onXiugai(record.key)"
            >
            
              <a href="javascript:;" style="color: #00f;margin-right:10px;float:left;">修改</a>
          </div>
            <a-popconfirm  style="float:left"
              v-if="dataSource.length"
              title="确定删除吗？"
              cancelText="取消"
              okText="确定"
              @confirm="() => onDelete(record.key)"
            >
            
              <a href="javascript:;" style="color: red">删除</a>
            </a-popconfirm>
            <div style="float:left;margin-left:10px"  @click="() => addWorkTime(record)"        >
            
              <a href="javascript:;" style="color: #00f;margin-right:10px;float:left;">延长授权</a>
          </div>
          </template>
        </a-table>
      </div>
      <a-modal title="请输入需要延期的时间" :titleStyle="{fontSize:'22px'}" :visible='addtime' @ok="handleOkadd"
      @cancel="handleCancel" class="flex ppp111">    
      
          <p ><span style="font-size:18px;flex:1">已选择机器人:</span><span style="font-size:18px;flex:1;margin-left:40px;">{{addt.robot}}</span></p>
          <p ><span style="font-size:18px;flex:1">目前有效期:</span><span style="font-size:18px;flex:1;margin-left:60px;">{{addt.time}}</span></p>
          <p><span style="font-size:18px;flex:1">延长期限至(日期):</span><a-date-picker  style="flex:1;margin-left:10px;" @change="onChangez" /></p>
       
          <p><span style="font-size:18px;flex:1">工作时间点:</span>   <a-time-picker style="margin-left:53px;" :value="addt.start_timem" 
            @change="onChanget1"  format="HH:mm" />
              ~  <a-time-picker :value="addt.end_timem" style="margin-left:1px;" @change="onChanget2"  format="HH:mm" /></p>

      </a-modal>

    </div>
  </div>
</template>

<script>

import {
  addRobot,
  //
  ruleRobot,
  //
  robotList,
  //
  addWorkTime,
  changeRobot,
  changeRobotUp,
  deleteRobot,
  operatoinRobot,
  workRobot,
  disableRobot,
} from "@/api/robot";
import moment from "moment";


export default {
  data() {
    return {
      addt:{robot_id:'',time:'',end_date:'',start_timem:moment('08:00', 'HH:mm'),end_timem:moment('18:00', 'HH:mm')},
      addtime:false,
      xiugai:false,
      page: 1,
      xiugaiDefault:1,
      limit: 10,
      value: "",
      xiugailaiyuanObj:'',
      xiugaichuanshuObj:'',
      value2: "",
      start_time: "",
      end_time: "",
      start_date: "",
      end_data: "",
      defaultValue: "",
      robotCode: "",
      rangeDate: "",
      model: 1,
      duiyingguanxi:[],
      money: "",
      name: "",
      index:0,
      threerobot:'',
      dataSource: [],
      count: 2,
      nanmoshang:'',
      nvmoshang:'',
      tongyongxia:'',
      tanchuang:false,
      xiugailaiyuanCfg:'',
      chuanzhiId:'',
      pagination: {
        // 关于页码的配置属性
        total: 0,
        pageSize:8,
        pageNo: 1,
        showSizeChanger: false, // 显示可改变每页数量
        pageSizeOptions: ["8"], // 每页数量选项
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) =>
          this.onSizeChange(current, pageSize), // 改变每页数量时更新显示
        onChange: (page, pageSize) => this.onPageChange(page, pageSize), //点击页码事件
      },
      columns: [
        {
          title: "机器人",
          dataIndex: "title",
        },
        {
          title: "名称",
          dataIndex: "name",
        },
        {
          title: "机器人编码",
          dataIndex: "code",
        },
        {
          title: "工作日期",
          dataIndex: "date",
        },
        {
          title: "工作时间",
          dataIndex: "time",
        },

        {
          title: "操作",
          width:200,
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
    };
  },
  created() {
    this.aquireRobotList();
    this.duiyingguanxif()
  },
  methods: {
    onChanget1(time, timeString){
      console.log(time, timeString);
      this.addt.start_timem=time
    },
    onChanget2(time, timeString){
      console.log(time, timeString);
      this.addt.end_timem=time
    },
    onChangez(date, dateString) {
      console.log(dateString);
      this.addt.end_date = dateString;

    },
    moment(){},

    handleOkadd(){
      console.log(this.addt)
      this.addt.start_time= this.addt.start_timem.format(" HH:mm")
      this.addt.end_time= this.addt.end_timem.format(" HH:mm")
      addWorkTime(this.addt).then((res)=>{
        if(res.code==200){
          this.addtime = false
          this.$message.success("延长成功");
          this.aquireRobotList();
        }else{
          if(res.code==400){
            this.$message.error(res.msg);
          }else{
            this.$message.error("延长失败");
          }
          
        }
      })
      
    },
    handleCancel(){this.addtime=false},
    disabledDate(current){
      return current && current <moment().subtract(1,'days').endOf('day')
    },
    disabledRangeTime (current) {
      const hours = moment().hours();
      const minutes = moment().minutes();
      const seconds = moment().seconds();
      if (current && moment().date() == current.date()) {
        return {
          disabledHours: () => this.range(0, hours),
          disabledMinutes: (selectedHour) =>
            selectedHour <= hours ? this.range(0, minutes) : [],
          disabledSeconds: (selectedHour, selectedMinute) =>
            selectedHour <= hours && selectedMinute.value <= minutes
            ? this.range(0, seconds)
            : []
        };
      }
      return {
        disabledHours: () => [],
        disabledMinutes: () => [],
        disabledSeconds: () => [],
      };
    },

    close(){
      this.tanchuang=false
      this.xiugai = false
    },
    // 分页里size改变触发的函数
    onSizeChange(_, pageSize) {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = pageSize;
      this.aquireRobotList();
    },
    // 页面改变时
    onPageChange(page, pageSize) {
      console.log(page, pageSize);
      this.pagination.pageNo = page;
      this.aquireRobotList();
    },
    onRangeChange(date, dateString) {
      console.log(dateString);
      this.start_date = dateString[0];
      this.end_date = dateString[1];
    },
    addDiv(){
       this.tanchuang = true;
    },
    reset() {
      this.value = "";
      this.value2 = "";
      this.start_time = "";
      this.end_time = "";
      this.robotCode = "";
      this.money = "";
      this.name = "";
    },
    handleChange(value) {
      console.log(`selected ${value}`);
      this.model = value;

      if(value == 1){
        this.index =0
        console.log(this.threerobt)
      }else if(value ==2){
        this.index =1
        console.log(this.threerobt)
      }else if(value == 3 ){
        this.index =2
        console.log(this.threerobt)
      }
      //console.log(this.threerobt)
      //this.threerobt = nanmoshang
    },
    handleChangex(value) {
      console.log(`selected ${value}`);
      this.chuanzhiId= value

    },
    getCurrentStyle(current, today) {
      const style = {};
      if (current.date() === 1) {
        style.border = "1px solid #1890ff";
        style.borderRadius = "50%";
      }
      return style;
    },
    onChange(time, string) {
      console.log(time, string);
      this.value = time;
      this.start_time = string;
    },
    onChange2(time, string) {
      console.log(time, string);
      this.value2 = time;
      this.end_time = string;
    },
    onCellChange(key, dataIndex, value) {
      const dataSource = [...this.dataSource];
      const target = dataSource.find((item) => item.key === key);
      if (target) {
        target[dataIndex] = value;
        this.dataSource = dataSource;
      }
    },
    // 删除
    onDelete(key) {
      console.log(key, this.dataSource[key]);
      deleteRobot({
        id: this.dataSource[key].id,
      })
        .then((res) => {
          console.log(res);
          if (res.msg == "操作成功") {
            this.$message.success("删除机器人成功");
            const dataSource = [...this.dataSource];
            this.dataSource = dataSource.filter((item) => item.key !== key);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addWorkTime(record){
      this.addtime=true
      console.log(record)
      this.addt.robot_id=record.id
      this.addt.robot=record.title
      this.addt.time=record.end_date
    },
    onXiugai(key){
      console.log(key)
      
      changeRobot({
        id: this.dataSource[key].id,
      }).then(res=>{
        console.log(res)
        this.xiugailaiyuanObj = res.data
        this.xiugailaiyuanCfg = JSON.parse(res.data.cfg)
        //this.xiugailaiyuanCfg = res.data.cfg)
        console.log(this.xiugailaiyuanCfg )
        this.xiugaiDefault = res.data.model
        this.chuanzhiId= res.data.model
        console.log(this.xiugaiDefault)
        this.xiugai = true;

      })
    },

 
    handleAdd() {
      const { count, dataSource } = this;
      const newData = {
        key: count,
        name: `Edward King ${count}`,
        age: 32,
        address: `London, Park Lane no. ${count}`,
      };
      this.dataSource = [...dataSource, newData];
      this.count = count + 1;
    },
    // 获取规则

    // 获取机器人列表
    aquireRobotList() {

      ruleRobot().then((res)=>{
        console.log(res)
         this.nanmoshang = res.data[0].attr
         this.nvmoshang = res.data[1].attr
         this.tongyongxia = res.data[2].attr
        //this.threerobot = JSON.parse(res.data)
        this.threerobot = res.data
        console.log(this.threerobot)
        for(let key in this.threerobot){
        let id = this.threerobot[key].id
        let name = this.threerobot[key].name
        this.duiyingguanxi[id] = name
      }
      console.log(this.duiyingguanxi)
        console.log(res.data)
      }).catch((error)=>{
        console.log('eee')
        console.log(error)
      })

      robotList({
        page: this.pagination.pageNo,
        limit: this.pagination.pageSize,
      })
        .then((res) => {
          console.log('jqiren')
           console.log(res);
          let result = res.data;
          this.pagination.total = result.count;
          result.list.map((item, index) => {
            item.key = index;
            item.date = item.start_date + "~" + item.end_date;
            item.time = item.start_time + "~" + item.end_time;
          });
          this.dataSource = [];
          this.dataSource.push(...result.list);
          console.log(this.dataSource)
          this.pagination.total = res.data.count;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    duiyingguanxif(){
      
      for(let key in this.threerobot){
        let id = this.threerobot[key].id
        let name = this.threerobot[key].name
        this.duiyingguanxi[id] = name
      }
      console.log(this.duiyingguanxi)
    },
    // 添加机器人12313123123
    addRobotEvent() {
      if (this.name == "" || this.isNull(this.name)) {
        this.$message.error("机器人名称不能为空");
        return;
      }
      if (this.robotCode == "" || this.isNull(this.robotCode)) {
        this.$message.error("机器人编码不能为空");
        return;
      }
      if (this.robotCode.length < 6) {
        this.$message.error("机器人编码长度不能低于6位");
        return;
      }
      if (this.start_date == "") {
        this.$message.error("请选择工作日期");
        return;
      }
      if (this.start_time == "") {
        this.$message.error("请选择工作开始时间");
        return;
      }
      if (this.end_time == "") {
        this.$message.error("请选择工作结束时间");
        return;
      }
      if (this.money == "" || this.money == 0) {
        this.$message.error("价格不能为空或者为0");
        return;
      }
      addRobot({
        model: this.model,
        name: this.name,
        code: this.robotCode,
        start_time: this.start_time,
        end_time: this.end_time,
        start_date: this.start_date,
        end_date: this.end_date,
        money: this.money,
        shape_cfg:JSON.stringify(this.threerobot[this.index].attr)
        //shape_cfg:this.threerobot[this.index].attr
      })
        .then((res) => {
          console.log(res);
          if (res.msg == "操作成功") {
            this.$message.success("机器人添加成功");
            this.aquireRobotList();
            this.reset();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    xiugaiRobotEvent(){
      //console.log(this.xiugailaiyuanObj.id)
      console.log(this.chuanzhiId)
      console.log(this.duiyingguanxi[this.chuanzhiId])
      console.log(this.xiugailaiyuanObj.name)
      console.log(this.xiugailaiyuanObj.money)
      console.log(this.xiugailaiyuanCfg)
      
      let aaa = {model:this.chuanzhiId,title:this.duiyingguanxi[this.chuanzhiId],name:this.xiugailaiyuanObj.name,money:this.xiugailaiyuanObj.money,shape_cfg:this.xiugailaiyuanCfg}
      console.log(aaa)

      let jjjsss = JSON.stringify(this.xiugailaiyuanCfg)
      changeRobotUp({id:this.xiugailaiyuanObj.id,model:this.chuanzhiId,title:this.duiyingguanxi[this.chuanzhiId],name:this.xiugailaiyuanObj.name,money:this.xiugailaiyuanObj.money,shape_cfg:jjjsss}).then(res=>{
        console.log(res)
        if(res.msg == '操作成功'){
          alert('修改成功')
        }
      
      })
      //console.log(changeRobotUp)
    },
    // 判断是否为空
    isNull(str) {
      if (str == "") return true;
      var regu = "^[ ]+$";
      var re = new RegExp(regu);
      return re.test(str);
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-size: 20px;
}
.text-a-right{text-align:right;}
.content_item {
  .item {
    .item_title {
      font-size: 16px;
    }
    span {
      font-size: 14px;
    }
    .list_title {
      color: #000000;
    }
    .list_msg {
      color: #7c7c7c;
    }
    div {
      font-size: 14px;
      color: #000000;
    }
    .item_box {
      div {
        width: 25%;
      }
    }
    span {
      font-size: 14px;
    }
  }
}
</style>
<style lang="less">
:deep(.ant-modal-title) {
  font-size:20px!important;

}
  .liebiao1{width:50%;float:left;}
  .sg_input{width:130px;}
// .ant-time-picker{width:142px!important;}
  .ppp111str{width:100px;    justify-content: right;    float: left;}
  .ppp111{flex:1 ;justify-content: flex-end;}
.rob_str{flex:1}  
.input_box{flex:1}
.bm_right{margin-right:7px;}  
  .tixing{text-align: left;width:25%;float:left;}
  .bot_input{width:2rem;}
  .rob_button{clear:both;text-align: center;}
.robot {
  .editable-cell {
    position: relative;
  }
  .add_bot{
text-align: left;font-size: 1.2rem;margin-top:-10px;line-height: 50px;padding-left:15px;
  }
  .bot_base{font-size:0.9rem;text-align: left;background-color: #F6F9FE;line-height: 30px;color:#000;font-weight: 600;padding-left:15px;}
  .icons-list {    position: absolute;
    right: 10px;
    top: 10px;}
  .icons-list .anticon {
  margin-right: 6px;
  font-size: 24px;
}
.tanchuangc{width: 58%;
    padding: 20px;
    margin: 0 auto;
    left: 10%;
    background-color: #fefefe;
    z-index: 90;
    border-radius:20px;
    position: absolute;
    border: 0px solid #ccc;}
  .editable-cell-input-wrapper,
  .editable-cell-text-wrapper {
    padding-right: 24px;
  }

  .editable-cell-text-wrapper {
    padding: 5px 24px 5px 5px;
  }

  .editable-cell-icon,
  .editable-cell-icon-check {
    position: absolute;
    right: 0;
    width: 20px;
    cursor: pointer;
  }

  .editable-cell-icon {
    line-height: 18px;
    display: none;
  }

  .editable-cell-icon-check {
    line-height: 28px;
  }

  .editable-cell:hover .editable-cell-icon {
    display: inline-block;
  }

  .editable-cell-icon:hover,
  .editable-cell-icon-check:hover {
    color: #108ee9;
  }

  .editable-add-btn {
    margin-bottom: 8px;
  }
  tr td:nth-child(4) {
    text-align: left;
    a {
      color: red;
    }
  }
  th:nth-child(4) {
    text-align: left;
  }
}
</style>
